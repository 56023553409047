@charset "utf-8";

// 추가 인클루드는 _setting 안에다가 하기 여기 인클루드는 _setting 하나만 나와있게 관리하기
@import '../../_src/_scss/_setting';

// http://wit.nts-corp.com/2017/02/13/4258
// 웹폰트에 대한 참고 사이트 입니다.
// 깜빡거림 현상 같은거 해결 방법 있음
// fontfaceobserver.js


/*! Nanum Barun Gothic (Korean) http://hangeul.naver.com */
// @font-face {
// 	font-family: 'Nanum Gothic';
// 	font-style: bold;
// 	font-weight: 700;
// 	src: url(font/NanumGothic-Bold.eot);/* IE 호환성 보기 */
// 	src: url(font/NanumGothic-Bold.eot?#iefix) format('embedded-opentype'),/* IE 6 ~ 8 */
// 		url(font/NanumGothic-Bold.woff2) format('woff2'),/* WOFF2 지원 브라우저 */
// 		url(font/NanumGothic-Bold.woff) format('woff'),모던 브라우저
// 		url(font/NanumGothic-Bold.ttf) format('truetype');
// }


// @font-face {
// 	font-family: NanumSquare;
// 	src: url(NanumSquareR.eot); /* IE 6 ~ 8, IE 호환성 보기 */
// 	src: local(☺),
// 		url(NanumSquareR.woff2) format('woff2'), /* IE Edge, WOFF2 지원 브라우저 */
// 		url(NanumSquareR.woff) format('woff'), /* IE 9+, 모던 브라우저 */
// 		url(NanumSquareR.ttf) format('truetype');
// }

// local(☺)

//- 웹폰트를 사용하는 경우 font stylesheet 를 사용 합니다.
//- 위 소스는 네이버의 나눔고딕 예제 입니다.
//- 폰트의 경로등은 프로젝트시 개발그룹과 상의해서 정하세요.
//- 폰트 파일은 요청해서 받으시기 바랍니다.
//- 모바일에서는 웹폰트는 비추천입니다. 폰트다운속도도 느리고 다운받는것 전부 사용자 데이터 요금입니다.

// :lang(en){font-family:"맑은 고딕";color:red;}
// :lang(ko){font-family:"궁서";color:blue;}
// 해당영역에 {영어폰트(영어만 지원해야함), 한글폰트, 기본폰트}로 순으로 폰트 설정하고 숫자만 인라인 테그로 처리

// Noto Sans JP : Thin 100 / Light 300 / Regular 400 / Medium 500 / Bold 700
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);

// Noto Sans SC : Thin 100 / Light 300 / Regular 400 / Medium 500 / Bold 700
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap);


@font-face {
	font-family: 'NotoSans-Light';
	// font-weight: 300;
	src: url('../fonts/notosanskr/NotoSans-Light.eot'); /* IE 6 ~ 8, IE 호환성 보기 */
	src: local(☺),
		url('../fonts/notosanskr/NotoSans-Light.woff2') format('woff2'), /* IE Edge, WOFF2 지원 브라우저 */
		url('../fonts/notosanskr/NotoSans-Light.woff') format('woff'), /* IE 9+, 모던 브라우저 */
		url('../fonts/notosanskr/NotoSans-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSans-DemiLight';
	// font-weight: 350;
	src: url('../fonts/notosanskr/NotoSans-DemiLight.eot'); /* IE 6 ~ 8, IE 호환성 보기 */
	src: local(☺),
		url('../fonts/notosanskr/NotoSans-DemiLight.woff2') format('woff2'), /* IE Edge, WOFF2 지원 브라우저 */
		url('../fonts/notosanskr/NotoSans-DemiLight.woff') format('woff'), /* IE 9+, 모던 브라우저 */
		url('../fonts/notosanskr/NotoSans-DemiLight.ttf') format('truetype');
}


@font-face {
	font-family: 'NotoSans-Medium';
	// font-weight: 500;
	src: url('../fonts/notosanskr/NotoSans-Medium.eot'); /* IE 6 ~ 8, IE 호환성 보기 */
	src: local(☺),
		url('../fonts/notosanskr/NotoSans-Medium.woff2') format('woff2'), /* IE Edge, WOFF2 지원 브라우저 */
		url('../fonts/notosanskr/NotoSans-Medium.woff') format('woff'), /* IE 9+, 모던 브라우저 */
		url('../fonts/notosanskr/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSans-Bold';
	// font-weight: 700;
	src: url('../fonts/notosanskr/NotoSans-Bold.eot'); /* IE 6 ~ 8, IE 호환성 보기 */
	src: local(☺),
		url('../fonts/notosanskr/NotoSans-Bold.woff2') format('woff2'), /* IE Edge, WOFF2 지원 브라우저 */
		url('../fonts/notosanskr/NotoSans-Bold.woff') format('woff'), /* IE 9+, 모던 브라우저 */
		url('../fonts/notosanskr/NotoSans-Bold.ttf') format('truetype');
}



  /* barlow-500 - Medium */
  @font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/barlow/barlow-v4-latin-500.eot'); /* IE9 Compat Modes */
	src: local('Barlow Medium'), local('Barlow-Medium'),
		 url('../fonts/barlow/barlow-v4-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/barlow/barlow-v4-latin-500.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/barlow/barlow-v4-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  }

  /* barlow-600 - Semi-bold */
  @font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/barlow/barlow-v4-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
		 url('../fonts/barlow/barlow-v4-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/barlow/barlow-v4-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/barlow/barlow-v4-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  }

  /* barlow-700 - Bold */
  @font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/barlow/barlow-v4-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Barlow Bold'), local('Barlow-Bold'),
		 url('../fonts/barlow/barlow-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/barlow/barlow-v4-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/barlow/barlow-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  }

// 아래는 fontfaceobserver.js 사용할때 쓰는 클래스들
.fonts-load{visibility: hidden;}
// .font-load-end{visibility: visible;}
// .fonts-loaded .font-load{visibility: visible;}
.fonts-loaded .fonts-load {visibility: visible;}
