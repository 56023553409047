@charset "UTF-8";
/**
	* Computes a CSS calc function that betweens a value from
	* A to B over viewport-width A to viewport-width B.
	* Requires a media query to cap the value at B.
	* vw 단위를 활용하여 미디어쿼리 분기의 중간 상태값을
	* 계산해주는 SASS 함수.
	* 간격이나 폰트사이즈에 적용하면 자연스러운
	* 반응형 사이즈 처리가 가능 함.
	* 참고사이트 http://dohoons.com/blog/1364
	*/
/**
	* Read the article: http://sassbreak.com/viewport-relative-headings-with-sass
	* px 를 vw 로 자동으로 바꿔 줌.
	* max-breakpoint 를 설정하면 미디어쿼리 브레이크 포인트가 됨.
	* 그래서 미디어쿼리 브레이크 포인트에 맞춰 px 가 vw 로 변환됨.
	* 100% 보다 더 크게 적용 하고 싶으면 return 부분을 수정하면됨.
	* 참고사이트 http://sassbreak.com/viewport-relative-headings-with-sass/
	*/
/*! Nanum Barun Gothic (Korean) http://hangeul.naver.com */
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap);
@font-face {
  font-family: 'NotoSans-Light';
  src: url("../fonts/notosanskr/NotoSans-Light.eot");
  /* IE 6 ~ 8, IE 호환성 보기 */
  src: local(☺), url("../fonts/notosanskr/NotoSans-Light.woff2") format("woff2"), url("../fonts/notosanskr/NotoSans-Light.woff") format("woff"), url("../fonts/notosanskr/NotoSans-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSans-DemiLight';
  src: url("../fonts/notosanskr/NotoSans-DemiLight.eot");
  /* IE 6 ~ 8, IE 호환성 보기 */
  src: local(☺), url("../fonts/notosanskr/NotoSans-DemiLight.woff2") format("woff2"), url("../fonts/notosanskr/NotoSans-DemiLight.woff") format("woff"), url("../fonts/notosanskr/NotoSans-DemiLight.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSans-Medium';
  src: url("../fonts/notosanskr/NotoSans-Medium.eot");
  /* IE 6 ~ 8, IE 호환성 보기 */
  src: local(☺), url("../fonts/notosanskr/NotoSans-Medium.woff2") format("woff2"), url("../fonts/notosanskr/NotoSans-Medium.woff") format("woff"), url("../fonts/notosanskr/NotoSans-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSans-Bold';
  src: url("../fonts/notosanskr/NotoSans-Bold.eot");
  /* IE 6 ~ 8, IE 호환성 보기 */
  src: local(☺), url("../fonts/notosanskr/NotoSans-Bold.woff2") format("woff2"), url("../fonts/notosanskr/NotoSans-Bold.woff") format("woff"), url("../fonts/notosanskr/NotoSans-Bold.ttf") format("truetype"); }

/* barlow-500 - Medium */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/barlow/barlow-v4-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Barlow Medium"), local("Barlow-Medium"), url("../fonts/barlow/barlow-v4-latin-500.woff2") format("woff2"), url("../fonts/barlow/barlow-v4-latin-500.woff") format("woff"), url("../fonts/barlow/barlow-v4-latin-500.ttf") format("truetype"); }

/* barlow-600 - Semi-bold */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/barlow/barlow-v4-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Barlow SemiBold"), local("Barlow-SemiBold"), url("../fonts/barlow/barlow-v4-latin-600.woff2") format("woff2"), url("../fonts/barlow/barlow-v4-latin-600.woff") format("woff"), url("../fonts/barlow/barlow-v4-latin-600.ttf") format("truetype"); }

/* barlow-700 - Bold */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/barlow/barlow-v4-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Barlow Bold"), local("Barlow-Bold"), url("../fonts/barlow/barlow-v4-latin-700.woff2") format("woff2"), url("../fonts/barlow/barlow-v4-latin-700.woff") format("woff"), url("../fonts/barlow/barlow-v4-latin-700.ttf") format("truetype"); }

.fonts-load {
  visibility: hidden; }

.fonts-loaded .fonts-load {
  visibility: visible; }
